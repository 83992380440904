<template>
    <v-row no-gutters>
        <v-col cols="12">
            <AccountBar :organizationId="$route.params.organizationId" :account="account" class="mb-6" v-if="account"></AccountBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link>
                    <template v-if="organization">
                    &gt; <router-link :to="{ name: 'organization-dashboard', params: { realm: this.$route.params.organizationId } }">{{ organization.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card class="pa-0" v-if="service">
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>Service</v-app-bar-title>
                            <v-spacer/>
                            <span style="font-size: 0.7em;" class="text-end">Account ID: {{ accountIdText }}<br>Service ID: {{ serviceIdText }}</span>
                            <!-- TODO: enable invite new users only for organizations with business or enterprise add-ons; organizations on the essential plan have only one admin -->
                            <!-- <v-btn icon :to="{ name: 'organization-invite-user', params: { organizationId: this.$route.params.organizationId } }">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn> -->
                        </v-app-bar>
                        <v-simple-table dense>
                            <template #default>
                                <tbody>
                                    <tr>
                                        <th style="min-width: 150px;">Name</th>
                                        <td style="width: 100%;">
                                            {{ service.name }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Type</th>
                                        <td style="width: 100%;">
                                            {{ service.type }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Active</th>
                                        <td style="width: 100%;">
                                            {{ service.is_active }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Description</th>
                                        <td style="width: 100%;">
                                            {{ service.description }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Ref</th>
                                        <td style="width: 100%;">
                                            {{ service.ref }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Alias</th>
                                        <td style="width: 100%;">
                                            {{ service.alias }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Product ID</th>
                                        <td style="width: 100%;">
                                            {{ service.product_id }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Order ID</th>
                                        <td style="width: 100%;">
                                            {{ service.order }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Price</th>
                                        <td style="width: 100%;">
                                            <template v-if="service.price">
                                                <!-- TODO: move the price display components from customer-website-html5 to reusable library so we can show the price details here the same as customer would see at checkout -->
                                                {{ service.price.id }}
                                            </template>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Stripe Subscription ID</th>
                                        <td style="width: 100%;">
                                            {{ service.stripe_subscription_id }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Created On</th>
                                        <td style="width: 100%;">
                                            {{ formatDate(service.created_on) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import { fromCurrencyAmountCSU } from '@libertyio/currency-util-js';
import AccountBar from '@/components/AccountBar.vue';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';

function formatId(input) {
    const parts = [];
    let i = 0;
    while (i < input.length) {
        const part = input.substr(i, 5);
        parts.push(part);
        i += 5;
    }
    return parts.join('-');
}

export default {
    components: {
        AccountBar,
        AccessDeniedOverlay,
    },
    data: () => ({
        organization: null,
        service: null,
        error: null,
        forbiddenError: false,
    }),
    computed: {
        ...mapState({
            organization: (state) => state.organization,
        }),
        serviceIdText() {
            return formatId(this.service.id);
        },
        accountIdText() {
            return formatId(this.service.account_id);
        },
        account() {
            return this.service?.account;
        },
    },
    methods: {
        async loadService() {
            try {
                this.$store.commit('loading', { loadService: true });
                const response = await this.$client.organization(this.$route.params.organizationId).accountService.get({ id: this.$route.params.serviceId, include: 'account' });
                if (response) {
                    this.service = response;
                } else {
                    this.service = null;
                }
            } catch (err) {
                console.error('loadService failed', err);
            } finally {
                this.$store.commit('loading', { loadService: false });
            }
        },
        viewServiceLink(item) {
            return { name: 'organization-view-service', params: { organizationId: this.$route.params.organizationId, serviceId: item.id } };
        },
        formatDate(timestamp) {
            return new Date(timestamp).toLocaleDateString();
        },
        formatAmount(currency, amountCSU) {
            return fromCurrencyAmountCSU(currency, amountCSU).toStringWithCurrencySymbol();
        },
        serviceStatusDisplay(status) {
            if (status === 'paid') {
                return 'Paid, waiting to be processed..';
            }
            if (status === 'activated') {
                return 'Processing...';
            }
            if (status === 'completed') {
                return 'Fulfilled';
            }
            return null;
        },
        /**
         * Reactivate means to try starting the fulfillment process again.
         * This is useful when an error occurred during fulfillment, so the
         * organization admin can press the reactivate button and try it again
         * after developers fix the issue.
         */
        async reactivate() {
            try {
                this.$store.commit('loading', { reactivateService: true });
                const response = await this.$client.organization(this.$route.params.organizationId).accountService.check({ id: this.$route.params.serviceId }, { item: 'reactivate' });
                if (response?.status === 'processed') {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Processed service' });
                } else if (response?.status === 'activated') {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Re-activated' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to re-activate service' });
                }
                this.loadService();
            } catch (err) {
                console.error('reactivateService failed', err);
            } finally {
                this.$store.commit('loading', { reactivateService: false });
            }
        },
    },
    mounted() {
        this.loadService();
    },
};
</script>
